import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { authenticate } from "../api/users";
import "../components/layout.scss";
import Layout from "../components/layout";

const StyledContainer = styled(Container)`
  padding-bottom: 40px;
`;
const StyledFormLabel = styled(Form.Label)`
  font-size: 18px;
  font-weight: 500;
  color: #271344;
  text-align: center;
`;

const Signin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const onSubmit = async info => {
    setLoading(true);
    try {
      const newInfo = {
        ...info,
        email: info.email.toLowerCase(),
      };
      const { token } = await authenticate(newInfo);
      localStorage.setItem("token", token);
      // window.location.href = "/child-program";
      // replace /child-program with /toddler-talk-plan-tutorial url
      window.location.href = '/toddler-talk-plan-tutorial'
    } catch (err) {
      setError("Incorrect email/password.");
      setLoading(false);
    }
  };
  const { register, handleSubmit, formState } = useForm({ mode: "onChange" });
  return (
    <Layout>
      <div id="content" className="site-content">
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-45"
                    className="post-45 page type-page status-publish hentry"
                  >
                    <div className="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="45"
                        className="elementor elementor-45"
                        data-elementor-settings="[]"
                      >
                        <div className="elementor-section-wrap">
                          <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="f6893e0"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                                data-id="9f5f77a"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="4522805"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                                        data-id="367b21f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                            data-id="9265126"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-left-to-right ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="133"
                                                    height="137"
                                                    src="../images/image-home-2.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                                        data-id="8869f2a"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                            data-id="8832270"
                                            data-element_type="widget"
                                            data-widget_type="cms_heading.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                                <h3 className="custom-heading">
                                                  <span>Sign In</span>
                                                </h3>
                                                <div className="custom-heading-description">
                                                  Welcome to Toddler Talk{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                            <div
                                              className="elementor-element elementor-element-6f927b2 elementor-widget elementor-widget-cms_image_single mb-3"
                                              data-id="6f927b2"
                                              data-element_type="widget"
                                              data-widget_type="cms_image_single.default"
                                            >
                                              <div className="elementor-widget-container">
                                                <div className="cms-image-single animation-none wow flash">
                                                  <div className="cms-img-box">
                                                    <StaticImage
                                                      placeholder="none"
                                                      width="99"
                                                      height="23"
                                                      src="../images/image-bee.png"
                                                      className="attachment-full size-full"
                                                      alt="Kindori"
                                                      loading="lazy"
                                                    />{" "}
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                            <div>
                                              <StyledContainer>
                                                <Row className="justify-content-center">
                                                  <Col sm="8" md="6">
                                                    {error && (
                                                      <Alert variant="danger">
                                                        {error}
                                                      </Alert>
                                                    )}
                                                    <Form
                                                      onSubmit={handleSubmit(
                                                        onSubmit
                                                      )}
                                                    >
                                                      <Form.Group controlId="formBasicEmail">
                                                        <StyledFormLabel>
                                                          <b>Email address</b>
                                                        </StyledFormLabel>
                                                        <Form.Control
                                                          name="email"
                                                          placeholder="Enter email"
                                                          type="email"
                                                          ref={register({
                                                            required: true,
                                                          })}
                                                        />
                                                      </Form.Group>

                                                      <Form.Group controlId="formBasicPassword">
                                                        <StyledFormLabel>
                                                          <b>Password</b>
                                                        </StyledFormLabel>
                                                        <Form.Control
                                                          type="password"
                                                          ref={register({
                                                            required: true,
                                                          })}
                                                          name="password"
                                                          placeholder="Password"
                                                        />
                                                      </Form.Group>
                                                      <Button
                                                        block
                                                        size="lg"
                                                        variant="primary"
                                                        type="submit"
                                                        disabled={
                                                          !formState.isValid ||
                                                          isLoading
                                                        }
                                                      >
                                                        Sign in{" "}
                                                        {isLoading && (
                                                          <Spinner
                                                            animation="border"
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                          />
                                                        )}
                                                      </Button>
                                                    </Form>
                                                    <StyledFormLabel className="forgot-password-link">
                                                      <p>
                                                        <a href="/reset-password" className="forgot-password-text">
                                                          Forgot your password ?
                                                        </a>
                                                      </p>
                                                    </StyledFormLabel>
                                                  </Col>
                                                </Row>
                                              </StyledContainer>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6be929f"
                                        data-id="6be929f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-97fbc33 cms-animation-bottom-to-top2 elementor-widget elementor-widget-cms_image_single"
                                            data-id="97fbc33"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-bottom-to-top ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="180"
                                                    height="186"
                                                    src="../images/image-home-3.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-b410ac4 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="b410ac4"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-0b4fe1f"
                                        data-id="0b4fe1f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-1bae692 cms-animation-bottom-to-top elementor-widget elementor-widget-cms_image_single"
                                            data-id="1bae692"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container mb-3">
                                              <div className="cms-image-single cms-animation-bottom-to-top2 ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="132"
                                                    height="116"
                                                    src="../images/image-home-1.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Signin;
